import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

class AnsweredQuestion {
  constructor(question, answer) {
    this.question = question;
    this.answer = answer;
  }
}

function AnsweredQuestionUI({answeredQuestion}){

  return (
    <Box mt={6}>
    <dd>
      <Typography
        variant="h4"
        color="textPrimary"
      >
        {answeredQuestion.question}
      </Typography>
    </dd>
    <dt>
      <Typography
        variant="body1"
        color="textSecondary"
        dangerouslySetInnerHTML={{__html: answeredQuestion.answer}}
      ></Typography>
    </dt>
  </Box>
  );
}


function FAQS({ className, ...rest }) {
  const classes = useStyles();

  const lang = getLanguageCode(useLocation());

  const technicalAnsweredQuestions = [
    new AnsweredQuestion(getI18nText(lang,"faq","technical_question_scanned"),getI18nText(lang,"faq","technical_answer_scanned")),
    new AnsweredQuestion(getI18nText(lang,"faq","technical_question_devices"),getI18nText(lang,"faq","technical_answer_devices")),
    new AnsweredQuestion(getI18nText(lang,"faq","technical_question_internet"),getI18nText(lang,"faq","technical_answer_internet"))
  ];

  const businessAnsweredQuestions = [
    new AnsweredQuestion(getI18nText(lang,"faq","technical_question_nextstep"),getI18nText(lang,"faq","technical_answer_nextstep")),
    new AnsweredQuestion(getI18nText(lang,"faq","technical_question_scannedalready"),getI18nText(lang,"faq","technical_answer_scannedalready")),
    //new AnsweredQuestion(getI18nText(lang,"faq","technical_question_process"),getI18nText(lang,"faq","technical_answer_process")),
  ];

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          color="textPrimary"
          align="center"
        >
          {getI18nText(lang,"faq","title")}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid
          container
          spacing={3}
          component="dl"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              {getI18nText(lang,"faq","technical")}
            </Typography>

            {
              technicalAnsweredQuestions.map((aq,i)=>(
                <AnsweredQuestionUI key={i} answeredQuestion={aq} />
              ))
            }

          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              {getI18nText(lang,"faq","commercial")}
            </Typography>

            {
              businessAnsweredQuestions.map((aq,i)=>(
                <AnsweredQuestionUI key={i} answeredQuestion={aq} />
              ))
            }            

          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
