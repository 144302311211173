//@ts-check
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,

    CircularProgress,
    Divider,

    Grid,


    TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'src/utils/axios';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import * as Yup from 'yup';
import { Section, SectionDataText, SectionText } from './Section';


function QuoteForm() {
  const [isAlertVisible, setAlertVisible] = useState(false);
  const lang = getLanguageCode(useLocation());

  return (
    <Section dark={true} title={getI18nText(lang,"quote","title")}>
    <Formik
      initialValues={{
        email: '',
        name: '',

        features: '',
        languages: '',
        duration: '',
        //provide:'',
        tour: '',
        
        message: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(getI18nText(lang,"common","required")),
        name: Yup.string().required(getI18nText(lang,"common","required")),

        features: Yup.string().required(getI18nText(lang,"common","required")),
        languages: Yup.string().required(getI18nText(lang,"common","required")),
        duration: Yup.string().required(getI18nText(lang,"common","required")),
        //provide:Yup.string(),
        tour: Yup.string(),
        
        message: Yup.string().required(getI18nText(lang,"common","required"))
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          console.log(JSON.stringify(values));

          const payload = {
            name:values.name,
            email:values.email,
            content:"[message]: "+values.message+"\n"+
            "[features]: "+values.features+"\n"+
            "[languages]: "+values.languages+"\n"+
            "[duration]: "+values.duration+"\n"+
            //"[provide]: "+values.provide+"\n"+
            "[tour]: "+values.tour+"\n"
          }

          //await wait(1000);
          const response = await axios.post("https://vblffmejaf.execute-api.eu-west-2.amazonaws.com/dev/email/send/json",payload);
          if(response.status!=200)
            throw new Error("api error");

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          setAlertVisible(true)
        } catch (error) {
            console.error(error);
          setStatus({ success: false });
          // @ts-ignore
          setErrors({ submit: error.message });
          setSubmitting(false);
          //todo, replace with alert
          alert(getI18nText(lang,"common","alternative"));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card>
          <CardHeader title={getI18nText(lang,"quote","invite")}/>
          <Divider />
          <CardContent>
          {isAlertVisible && (
              <Box mb={3}>
                <Alert
                  onClose={() => setAlertVisible(false)}
                  severity="info"
                >
                  {getI18nText(lang,"common","thankyou")}
                </Alert>
              </Box>
            )}          
            {isSubmitting ? (
              <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={getI18nText(lang,"common","your_email")}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />                  

                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={getI18nText(lang,"common","your_name")}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />                  

                  </Grid>
                </Grid>

                <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_features")}</b></SectionText>
                    <SectionDataText>{getI18nText(lang,"quote","note_features")}</SectionDataText>
                    <TextField
                      error={Boolean(touched.features && errors.features)}
                      fullWidth
                      helperText={touched.features && errors.features}
                      name="features"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.features}
                      variant="outlined"
                    />                       
                </Box>
                <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_languages")}</b></SectionText>
                    <SectionDataText>{getI18nText(lang,"quote","note_languages")}</SectionDataText>
                    <TextField
                      error={Boolean(touched.languages && errors.languages)}
                      fullWidth
                      helperText={touched.languages && errors.languages}
                      name="languages"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.languages}
                      variant="outlined"
                    />                       
                </Box>
                <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_duration")}</b></SectionText>
                    <SectionDataText>{getI18nText(lang,"quote","note_duration")}</SectionDataText>
                    <TextField
                      error={Boolean(touched.duration && errors.duration)}
                      fullWidth
                      helperText={touched.duration && errors.duration}
                      name="duration"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.duration}
                      variant="outlined"
                    />                       
                </Box>
                {/* <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_provide")}</b></SectionText>
                    <SectionDataText>{getI18nText(lang,"quote","note_provide")}</SectionDataText>
                    <TextField
                      error={Boolean(touched.provide && errors.provide)}
                      fullWidth
                      helperText={touched.provide && errors.provide}
                      name="provide"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.provide}
                      variant="outlined"
                    />                       
                </Box>                 */}
                <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_tour")}</b></SectionText>
                    <SectionDataText>{getI18nText(lang,"quote","note_tour")}</SectionDataText>
                    <TextField
                      error={Boolean(touched.tour && errors.tour)}
                      fullWidth
                      helperText={touched.tour && errors.tour}
                      name="tour"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tour}
                      variant="outlined"
                    />                       
                </Box>






                <Box mt={2}>
                    <SectionText><b>{getI18nText(lang,"quote","question_message")}</b></SectionText>
                    <TextField
                      error={Boolean(touched.message && errors.message)}
                      fullWidth
                      helperText={touched.message && errors.message}
                      label={getI18nText(lang,"common","your_message")}
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.message}
                      variant="outlined"
                      multiline
                    rows={4}
                    rowsMax={4}
                    />
                </Box>
                <Box mt={2} style={{display:"flex",justifyContent:"center"}}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                    style={{margin:'auto'}}
                  >
                    {getI18nText(lang,"common","send_message")}
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
    </Section>
  );
}

/*

*/

export default QuoteForm;
