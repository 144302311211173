
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Link,
  Box,
  Container,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#111111',
    paddingTop: 64,
    paddingBottom: 64,
    color: 'white'
  },
}));

function Footer1({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
      <Box
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <Typography
          variant="body1"
        >
          {getI18nText(lang,"footer","product")}
          </Typography>
          
        </Box>
      <Box
          mtb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <a href="https://optimosoft.com">
           <img width="220" height="50" src="/static/logo_optimosoft.png"  />
           </a>
        </Box>
        <Box
          mtb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
           <Link
        href="https://optimosoft.com"
          variant="body1"
          align="center"
          style={{color:"white"}}
        >
          {getI18nText(lang,"footer","visit")}
        </Link>
        </Box>
        <Box
          mtb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <Typography
          variant="body1"
          style={{paddingRight:10}}
        >
          {getI18nText(lang,"footer","contact")}
          </Typography>
          
        <IconButton component="a" href="https://twitter.com/optimosoft_ltd" >
            <TwitterIcon style={{ fill: "#1DA1F2" }} />
          </IconButton>
        <IconButton component="a" href="https://www.facebook.com/optimosoft" >
            <FacebookIcon style={{ fill: "#4267B2" }} />
          </IconButton>
        <IconButton component="a" href="https://www.youtube.com/channel/UCqhOwIdX2dlxT6uN5JOPigA" >
            <YouTubeIcon style={{ fill: "#FF0000" }} />
          </IconButton>            
        </Box>
    
      <Typography
          variant="body1"
          align="center"
        >
          © 2021
        </Typography>

      </Container>
    </div>
  );
}

Footer1.propTypes = {
  className: PropTypes.string
};

export default Footer1;
