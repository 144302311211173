import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Link,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import wait from 'src/utils/wait';
import axios from 'src/utils/axios';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


function ContactForm() {
  const [isAlertVisible, setAlertVisible] = useState(false);
  const lang = getLanguageCode(useLocation());

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        content: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(getI18nText(lang,"common","required")),
        name: Yup.string().required(getI18nText(lang,"common","required")),
        content: Yup.string().required(getI18nText(lang,"common","required"))
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          console.log(JSON.stringify(values));

          //await wait(1000);
          const response = await axios.post("https://vblffmejaf.execute-api.eu-west-2.amazonaws.com/dev/email/send/json",values);
          if(response.status!=200)
            throw new Error("api error");

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          setAlertVisible(true)
        } catch (error) {
            console.error(error);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
          //todo, replace with alert
          alert(getI18nText(lang,"common","alternative"));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card>
          <CardHeader title={getI18nText(lang,"contacts","invite")}/>
          <Divider />
          <CardContent>
          {isAlertVisible && (
              <Box mb={3}>
                <Alert
                  onClose={() => setAlertVisible(false)}
                  severity="info"
                >
                  {getI18nText(lang,"common","thankyou")}
                </Alert>
              </Box>
            )}          
            {isSubmitting ? (
              <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={getI18nText(lang,"common","your_email")}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />                  

                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={getI18nText(lang,"common","your_name")}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />                  

                  </Grid>
                </Grid>
                <Box mt={2}>

                <TextField
                      error={Boolean(touched.content && errors.content)}
                      fullWidth
                      helperText={touched.content && errors.content}
                      label={getI18nText(lang,"common","your_message")}
                      name="content"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.content}
                      variant="outlined"
                      multiline
                    rows={4}
                    rowsMax={4}
                    />
                </Box>
                <Box mt={2} style={{display:"flex",justifyContent:"center"}}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                    style={{margin:'auto'}}
                  >
                    {getI18nText(lang,"common","send_message")}
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

/*

*/

export default ContactForm;
