// @ts-check
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Footer1 from 'src/components/EpicVisit360/Footer1';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {}
}));

function PageWrapper(props) {
  const classes = useStyles();
  useEffect(() => {
    console.log("scroll!")
    //window.scrollTo(0, 0)
    document.getElementById('mytop').scrollIntoView();
  })
  return (
    <
// @ts-ignore
    Page
      className={classes.root}
      title={props.title}
    >
        <div id="mytop"/>
        {props.children}
      <Footer1 className="null"/>
    </Page>
  );
}

PageWrapper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string
};

export default PageWrapper;
