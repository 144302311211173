// @ts-check

import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Section } from './Section';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    //cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  screenshot: {
      display:"block",
      margin:"auto",
    width:"60%",
    maxWidth:"60%",
  },  
  recommendedProduct: {
    backgroundColor: "#ffd5c1",// theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  }
}));

PricingColumn.propTypes = {
    recommended: PropTypes.bool,
    title: PropTypes.string,
    price: PropTypes.string,
    features: PropTypes.array,
    screenshot: PropTypes.string
};


function PricingColumn(props) {
    const classes = useStyles();
    const lang = getLanguageCode(useLocation());
    return (
      <Paper
        className={props.recommended? clsx(classes.product, classes.recommendedProduct) : classes.product}
        elevation={1}
      >
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          {props.title}
        </Typography>
        <div>
        <Typography
            component="span"
            display="inline"
            variant="subtitle2"
            color="textSecondary"
          >
            {getI18nText(lang,"pricing","from")}&nbsp;
          </Typography>        
          <Typography
            component="span"
            display="inline"
            variant="h3"
            color="textPrimary"
          >
            {props.price}
          </Typography>

        </div>
        <Box my={2}>
          <Divider />
        </Box>
        <Typography
          variant="body2"
          color="textPrimary"
        >
            {
                props.features.map((f,i)=>(<b key={i}>&nbsp;&nbsp;✓&nbsp;&nbsp;{f} <br/></b>))
            }
        </Typography>
        <Box my={2}>
          <Divider />
        </Box>
        <img
                  alt="Product"
                  className={classes.screenshot}
                  src={props.screenshot}
                />
      </Paper>
    );
}



function PricingTable(props) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());
  return (
<Section dark={true} title={getI18nText(lang,"pricing","slogan")}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
                <PricingColumn 
    recommended={false}
    title={getI18nText(lang,"pricing","gamifiedvisit_title")}
    price={getI18nText(lang,"pricing","gamifiedvisit_price")}
    features={getI18nText(lang,"pricing","gamifiedvisit_features")}
    screenshot={"/static/screenshots/screenshot_grace.png"}
                />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
               <PricingColumn 
    recommended={true}
    title={getI18nText(lang,"pricing","customgame_title")}
    price={getI18nText(lang,"pricing","customgame_price")}
    features={getI18nText(lang,"pricing","customgame_features")}
    screenshot={"/static/screenshots/screenshot_stleger.png"}
                />
            </Grid>
          </Grid>
    </Section>
  );
}

export default PricingTable;
