// @ts-check

import React from 'react';
import ContactUs from 'src/components/EpicVisit360/ContactUs';
import PageWrapper from './PageWrapper';
import { useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText } from 'src/utils/i18n';


function ContactUsPage() {
  const lang = getLanguageCode(useLocation());
  return (
    <PageWrapper title={getI18nText(lang,"menu","contact")} >
      <ContactUs className={null} />
    </PageWrapper>
  );
}
export default ContactUsPage;
