import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Divider,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCircle, faLandmark, faGraduationCap, faChessRook, faCamera, faLaptopCode, faServer } from '@fortawesome/free-solid-svg-icons'
import { brandingOrange } from 'src/theme';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


library.add(fab, faCircle, faLandmark, faGraduationCap, faChessRook, faCamera, faLaptopCode, faServer)

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function FALayeredIcon({ iconName, iconTransform = "shrink-6" }) {
  const bigWidth = useMediaQuery('(min-width:500px)');
  const iconSize = bigWidth?"3em":"2em";
  return (
    <div style={{ marginRight: "10px" }}>
      <span className="fa-layers fa-3x" style={{fontSize:iconSize}}>
        <FontAwesomeIcon icon="circle" color={brandingOrange} />
        <FontAwesomeIcon icon={iconName} inverse transform={iconTransform} />
      </span>
    </div>
  );
}

function SubCard({ name, image, iconName, iconTransform = "shrink-6" }) {
  const bigWidth = useMediaQuery('(min-width:500px)');
  const nameVariant = bigWidth?"h5":"h6";
  // to have responsive cells, add this to grid
  // xs={12}
  // md={4}
  return (
    <Grid
      item
      xs={4}
      md={4}
      
      align="center"
    >
      <img style={{ boxShadow: "5px 5px 8px #555555", width: "90%", maxWidth: "150px", margin: "10px", border: "1px solid black" }} src={image} />
      <Box display="flex" align="center" style={{ alignContent: "center", justifyContent: "center" }}>
        <FALayeredIcon iconName={iconName} iconTransform={iconTransform} />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography
            variant={nameVariant}
            gutterBottom
            color="textPrimary"
            style={{ verticalAlign: "middle" }}
          >
            {name}
          </Typography>
        </div>

      </Box>
    </Grid>
  )
}

class CardItem {
  constructor(name,image,icon,transform="shrink-6"){
    this.name = name;
    this.image = image;
    this.icon = icon;
    this.transform = transform;
  }
}

function IllustratedIdeaUI ({title,phrase,cardItems}) {
  return (
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Box mt={3}>
          <Grid
                container
                spacing={3}
              >
                {cardItems.map(ci=>(
                  <SubCard key={ci.name} name={ci.name} image={ci.image} iconName={ci.icon} iconTransform={ci.transform} />
                ))}
              </Grid>
            {/* <img src="/static/hardcoded_partners.png"/> */}
            <Typography variant="h3" color="textPrimary" style={{ margin: "20px auto", textAlign: "center" }} >
                  {phrase}
            </Typography>
        </Box>

      </Container>
  );
}



function IllustratedIdeas({ className, services, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  var title = getI18nText(lang,"partners","title")
  var phrase = getI18nText(lang,"partners","slogan")
  var cardItems = [
    new CardItem(getI18nText(lang,"partners","client_museum"),"/static/museum.jpg","landmark"),
    new CardItem(getI18nText(lang,"partners","client_historical") ,"/static/castle.jpg" ,"chess-rook"),
    new CardItem(getI18nText(lang,"partners","client_education"),"/static/classroom.jpg" ,"graduation-cap","shrink-6 left-2")
  ];
  if(services){
    var title = getI18nText(lang,"process","title")
    var phrase = getI18nText(lang,"process","slogan")
    cardItems = [
      new CardItem(getI18nText(lang,"process","process_scan"),"/static/scan.jpg","camera"),
      new CardItem(getI18nText(lang,"process","process_dev"),"/static/blender.jpg","laptop-code","shrink-6 left-2"),
      new CardItem(getI18nText(lang,"process","process_host"),"/static/server.jpg","server")
    ];
  }


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
          <IllustratedIdeaUI title={title} phrase={phrase} cardItems={cardItems}/>
    </div>
  );
}

IllustratedIdeas.propTypes = {
  className: PropTypes.string
};

export default IllustratedIdeas;
