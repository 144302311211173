
//@ts-checkz
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,

  Chip,

  Divider,
  Drawer,
  Hidden,

  IconButton,

  List,
  ListSubheader,

  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { matchPath, useLocation } from 'react-router';
import Logo from 'src/components/Logo';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { menuItems } from '../MenuContent';
import NavItem from './NavItem';
import { Link as RouterLink } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

function buildSideMenuContent(lang){
  var navConfig = [
    {
      subheader: 'MENU:',
      items: menuItems.map((mi,i)=>{
        return {
          title:getI18nText(lang,"menu",mi.link),
          href:'/'+lang+'/'+mi.link,
          info:(i!=menuItems.length-1)?null:() => (
            <Chip
              color="secondary"
              label="❮❮"
            />
          )
        }
      })
    },
  ];

  return navConfig;
}





function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();

  const lang = getLanguageCode(useLocation());
  const navConfig =  buildSideMenuContent(lang)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      <img style={{"maxWidth":"100%"}} src="/static/logoev360square.png" className={classes.mountainImage}/>  
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}
        style={ {"width":"100%","textAlign":"center","margin":"auto"} } >
        <IconButton component="a" href="https://twitter.com/optimosoft_ltd" >
            <TwitterIcon style={{ fill: "#1DA1F2" }} />
          </IconButton>
        <IconButton component="a" href="https://www.facebook.com/optimosoft" >
            <FacebookIcon style={{ fill: "#4267B2" }} />
          </IconButton>
        <IconButton component="a" href="https://www.youtube.com/channel/UCqhOwIdX2dlxT6uN5JOPigA" >
            <YouTubeIcon style={{ fill: "#FF0000" }} />
          </IconButton>     
        </Box>        
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
