import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 150,
    paddingBottom: 150,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '100%',
      height: 'auto',
      transform: 'rotateY(18deg) rotateX(6deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
      
      filter: 'drop-shadow(5px 5px 5px #222);'
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  image2:{
    maxWidth: '75%',
    height: 'auto'    
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/screenshot_with_logo.png"
                />
              </div>
            </Box>          
          </Grid>        
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="overline"
                color="secondary"
              >
                {getI18nText(lang,"hero","pretitle")}
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
              >
                {getI18nText(lang,"hero","title")}
              </Typography>
              <Typography
                variant="h3"
                color="textSecondary"
              >
               {getI18nText(lang,"hero","slogan")}
              </Typography>              
              <Box mt={3}>

                    {lang=='fr'?(                <Typography
                  variant="body1"
                  color="textSecondary"
                >

Attirez de nouveaux publics vers votre musée ou lieu historique avec nos visites virtuelles augmentées.
Nos fonctionnalités de ‘Gamification’ (ludification), ajoutées à votre espace numérisé à 360 ° créent un tout nouveau type d'expériences Web immersives, Mi-Éducatives/Mi-Ludiques.<br/>
Les visiteurs progressent d'une pièce à l'autre une fois qu'ils ont résolu des mini-jeux et des questions. 
Incluez un Guide Multilingue, des contenus multimédias, un scénario de jeu et bien plus encore…<br/>

<b>Offrons l’opportunité aux visiteurs de redécouvrir notre Patrimoine Culturel sous un jour nouveau.</b><br/><br/>

<b>Consultez notre présentation vidéo et n’hésitez pas à nous contacter pour de plus amples informations.</b><br/>

                      </Typography>):(                <Typography
                  variant="body1"
                  color="textSecondary"
                >

Reach new audiences for your museum or historical place with our augmented virtual tours. 
Our gamification features added to your 360°-scanned space create a new type of immersive web experiences half-game half-education. <br/>
Make visitors progress from room to room once they solved mini-games and questions. 
Include a multilingual guide, media content, a game story and a lot more. <br/>

<b>Let your visitors rediscover our cultural heritage from a new angle.</b><br/><br/>

<b>Check out our video presentation and contact us for more information.</b>

                      </Typography>)
                    }
              </Box>
              <div >
              <img className={classes.image2} src="/static/all_devices.png" />
              </div>
              {/* <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      30+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Demo Pages
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Complete Flows
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Components
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
