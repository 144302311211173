//@ts-check
import {
    Grid,
    makeStyles
} from '@material-ui/core';
import React from 'react';
import { Section } from './Section';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      item: {
        width:"100%",
        height: "150px",                
        padding: theme.spacing(1),

        //filter: 'drop-shadow(5px 5px 5px #222);',        
        display:"block",
        margin:"auto"
    },        
}));

export const LogoItem = (props) => { 
    const classes = useStyles();
    return (
        <Grid item xs={3}>
            <div className={classes.item}>
                <img width="100%" height="100%" style={{objectFit: "contain",overflow:"hidden"}} src={props.content}/>
            </div>
        </Grid>
    );
}

function Logos(props) {
  const classes = useStyles();

  return (
      <Section dark={false} title="">
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <LogoItem content="/static/logos/soissons.png"></LogoItem>
                    <LogoItem content="/static/logos/tunisie.png"></LogoItem>
                    <LogoItem content="/static/logos/unc.png"></LogoItem>
                    <LogoItem content="/static/logos/rammelsberg.png"></LogoItem>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <LogoItem content="/static/logos/matterport.png"></LogoItem>
                    <LogoItem content="/static/logos/threejs.png"></LogoItem>
                    <LogoItem content="/static/logos/webvr.png"></LogoItem>
                    <LogoItem content="/static/logos/360.jpeg"></LogoItem>
                </Grid>
            </Grid>
        </div>
      </Section>
  );
}

Logos.propTypes = {
    
};

export default Logos;
