// @ts-check


export class CaseStudyData{
    constructor(name,location,country,story,description,media,link){
        this.name = name;
        this.location = location;
        this.country = country;
        this.story = story;
        this.description = description
        this.media = media ; 
        this.link = link;
    }   
}
