// @ts-check

import React from 'react';
import Hero from 'src/components/EpicVisit360/Hero';
import Video from 'src/components/EpicVisit360/Video';
import FeatureList from 'src/components/EpicVisit360/FeatureList';
import IllustratedIdeas from 'src/components/EpicVisit360/IllustratedIdeas';
import UseCases from 'src/components/EpicVisit360/UseCases';
import FAQS from 'src/components/EpicVisit360/FAQS';
import Screenshots from 'src/components/EpicVisit360/Screenshots';
import ContactUs from 'src/components/EpicVisit360/ContactUs';
import PageWrapper from './PageWrapper';
import { useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText } from 'src/utils/i18n';
import Logos from 'src/components/EpicVisit360/Logos';

/*
    <a href="#chapter4">here</a>
      <Hero className={null}/>
      <a name="chapter4"></a>
      
https://www.npmjs.com/package/react-router-hash-link
npm install --save react-router-hash-link
*/

function HomePage() {
  const lang = getLanguageCode(useLocation());
  return (
    <PageWrapper title={getI18nText(lang,"menu","home")} >
      <Hero className={null}/>
      <Video className={null}/>
      <FeatureList className={null}/>
      <IllustratedIdeas services={false} className={null}/>
      <UseCases className={null}/>
      <Logos/>
      <ContactUs className={null} />
    </PageWrapper>
  );
}

export default HomePage;
