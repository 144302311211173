// ts-check

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Screenshots({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  var title = getI18nText(lang,"screenshots","title")

//<Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}>

/*
Multilingual guide 
Protected doors
MCQ
Map
VR girl 
Puzzle in cathedral 
Multiplayer museum 
Phoenix
Inventory 

*/

  var screenshots = [
    "screenshot_girlguide.png",
    "screenshot_question_map.png",
    "screenshot_subtitles.png",
    "screenshot_deburka.png",
    "screenshot_guide_closeddoor.png",
    "screenshot_question_mcq.png",
    "screenshot_lift_girl.png",
    "screenshot_cave.png",
    "screenshot_puzzle_cathedral.png",
    "screenshot_inventory.png",
    "screenshot_infobubbles.png",

    // "screenshot_expandinglift.png",
    // "screenshot_guide_cathedral.png",
    // "screenshot_nicechurch.png",    
    // "screenshot_multiplayercathedral.png",
    // "screenshot_phoenix.png",
    // "screenshot_uvlight.png"
  ];


    var indexes = Array.from({length: 8}, (_, i) => i + 1);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          color="textPrimary"
          align="center"
        >
          {title}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Carousel showArrows={true} > 
            {
                screenshots.map(s=>(
                    <div key={s}><img src={"/static/screenshots/"+s} /> </div>
                ))
            }
            </Carousel>        
      </Container>
    </div>
  );
}

Screenshots.propTypes = {
  className: PropTypes.string
};

export default Screenshots;
