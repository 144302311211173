// @ts-check
import {
  AppBar,
  Box,
  Button, IconButton,
  makeStyles, Toolbar,
  List,
  ListItem,
  ListItemText,  
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import {
  IconFlagFR, IconFlagUK
} from 'material-ui-flags';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText } from 'src/utils/i18n';
//doesn't seem to work import { HashLink } from 'react-router-hash-link';
import { Hidden, SvgIcon } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import { menuItems } from './MenuContent';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0f4558"//theme.palette.primary.main//theme.palette.background.default
  },
  toolbar: {
    backgroundColor: "#0f4558",
    height: 64
  },
  menuitem: {
    margin: 20,
    color:"white"
  },  
  logo: {
    //marginRight: theme.spacing(2)
    maxWidth:"25%",
    //width:"300px"
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`
  },
  linkText: {
    whiteSpace: "nowrap",
    padding: "0px 10px",
    margin: 1,
    textTransform: "none", 
    textDecoration: `none`,
    color: `white`
  },  
  active: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    borderBottom: "3px solid #dd5c35"
  },  
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));


function TopBar({ className,onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());
  const elements = useLocation().pathname.split('/');
  const page = elements[2];
  const theme = useTheme();
  const smallMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  function handleClick(newLang) {
    const newPath = "/"+newLang+"/"+page;
    console.log(newPath)
    history.push(newPath);
  }

  const relevantMenuItems = menuItems.slice(0,menuItems.length-1);

  // to hide section on mobile portrait: <Hidden xsDown></Hidden>
  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
            <IconButton 
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="large" >
                <MenuIcon style={{color:"white"}}/>
              </SvgIcon>
            </IconButton>
      
          </Hidden>            
          {/* <img src={"/static/logoev360"+(smallMobile?"square":"line")+".png"} className={classes.logo}/>   */}
            <Typography
          variant={smallMobile?"h5":"h3"}
          style={{padding:"7px",color:"white",border: "1px solid #dd5c35"}}
        >
          Epic Visit 360
          </Typography>        

        <Box flexGrow={1} />
        {
          //smallMobile?null:relevantMenuItems.map((mi)=>(<Typography className={classes.menuitem} variant="h5">{getI18nText(lang,"menu",mi.link)}</Typography>))

          smallMobile?null:(
            <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
          >
            {relevantMenuItems.map((mi) => (
                <ListItem activeClassName={classes.active} className={classes.linkText} button component={NavLink} to={'/'+lang+'/'+mi.link} key={getI18nText(lang,"menu",mi.link)} >
                  <ListItemText  primary={getI18nText(lang,"menu",mi.link)} />
                </ListItem>
            ))}
          </List>
          )



        }
        {/* 
        onClick={()=>document.getElementById("anchorlink").click()}
        <a id="anchorlink" href="#anchor" /> 

        and we need the actual link in the page
        <a name="anchor"/>
        */}
        <Button
        component={NavLink} to={'/'+lang+'/contact'} 
        
                            type="submit"
                            size={smallMobile?"small":"medium"}
        variant="contained" style={{textTransform: "none", backgroundColor:"#dd5c35",color:"white",margin:5}}>
          <Typography variant="h5">
            {getI18nText(lang,"menu","contact")}
          </Typography>
        </Button>
        <IconButton size={smallMobile?"small":"medium"} onClick={()=>handleClick("en")} ><IconFlagUK /></IconButton>
        <IconButton size={smallMobile?"small":"medium"} onClick={()=>handleClick("fr")}  ><IconFlagFR /></IconButton>

      </Toolbar>
    </AppBar>
  );
}

//  <a href="#anchor">

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
