//@ts-check

import {
  AppBar,
  Box,
  Button, IconButton,
  makeStyles, Toolbar,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import {
  IconFlagFR, IconFlagUK
} from 'material-ui-flags';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText } from 'src/utils/i18n';
//doesn't seem to work import { HashLink } from 'react-router-hash-link';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light//theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  // to hide section on mobile portrait: <Hidden xsDown></Hidden>
  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h3"
          style={{color:"white"}}
        >
          Epic Visit 360
          </Typography>
        <Box flexGrow={1} />

        <IconButton component={Link} to="/en/home" ><IconFlagUK /></IconButton>
        <IconButton component={Link} to="/fr/home" ><IconFlagFR /></IconButton>

        <Button 
         onClick={()=>document.getElementById("anchorlink").click()}
                            type="submit"
                    size="large"
        variant="contained" style={{backgroundColor:"#dd5c35",color:"white",marginLeft:12}}>
          {getI18nText(lang,"topbar","contact")}
        </Button>
        <a id="anchorlink" href="#anchor" />
      </Toolbar>
    </AppBar>
  );
}

//  <a href="#anchor">

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
