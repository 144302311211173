import i18nContentFR from 'src/lang/fr.json';
import i18nContentEN from 'src/lang/en.json';

export function getLanguageList(){
    return ["en","fr"];
}

export function getLanguageCode(location) {
    const elements = location.pathname.split('/');
    return elements[1];
}

export function getI18nSection(languageCode, domain) {
    const i18nContent = {
        fr: i18nContentFR,
        en: i18nContentEN,
    };
    return i18nContent[languageCode][domain];
}
export function getI18nText(languageCode, domain, textId) {
    return getI18nSection(languageCode,domain)[textId];
}


