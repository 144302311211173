//@ts-check
import {
    makeStyles
} from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { Section, SectionText } from './Section';

const useStyles = makeStyles((theme) => ({
  root: {
      
  }
}));

function CaseStudyIntro(props) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  return (
      <Section dark={true} title={getI18nText(lang,"menu","casestudies")}>
        <SectionText>
            <div className="content" dangerouslySetInnerHTML={{__html: getI18nText(lang,"casestudies","intro")}}/>
        </SectionText>
      </Section>
  );
}

CaseStudyIntro.propTypes = {
    
};

export default CaseStudyIntro;
