//@ts-check
import {
    Box,
    Container,
    Grid,
    Link,
    makeStyles
} from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { Section, SectionText } from './Section';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    image: {
        maxWidth: "50%",
        filter: 'drop-shadow(5px 5px 5px #222);',
        margin: "auto",
        display: "block",
        padding: "10px"
    },
    mikael: {
        textAlign: "center",
        fontWeight: "bold"
    },    
    linkphrase: {
        textAlign: "center"
    },
}));

function AboutUs(props) {
    const classes = useStyles();
    const lang = getLanguageCode(useLocation());

    return (
        <Section dark={true} title={getI18nText(lang, "menu", "aboutus")}>
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                        >

{lang=='fr'?
                            (<SectionText>
Epic Visit 360 est un produit phare de la société Optimosoft.
<br /><br />
Nous sommes une équipe composée de personnes très diverses qualifiées et passionnées par la Technologie et l'Innovation, opérant au Royaume-Uni et en France et avec des partenaires dans le Monde entier.
<br />
Nous combinons différents talents dans les domaines logiciels & technologiques en général et de conception d'expériences virtuelles à 360° particulier. Nous possédons les compétences pour augmenter et gamifier vos visites virtuelles ; de l'écriture d'histoires à l'implémentation technique en passant par la gestion de collaborations internationales dans les secteurs de la Culture et de l'Éducation.
<br /><br />
Nos Développeurs et Concepteurs de jeux sont prêts à vous aider depuis Londres ou Nancy. Nous travaillons avec des clients en Europe, qu'aux USA et en Afrique du Nord.
<br /><br />
La Technologie redéfinit tout ce qui est possible dans les secteurs de la Culture, de l'Éducation et du Patrimoine. De la Gamification à la Réalité Virtuelle, les opportunités sont innombrables. Travaillons donc ensemble.
<br /><br />
                            </SectionText>)
:
(<SectionText>
Epic Visit 360 is a product of the company Optimosoft.
<br /><br />
We are a diverse team of skilled individuals with a passion for Technology and Innovation, operating in the UK and France and with partners all over the world.
<br />
We have the right mix of talents when it comes to software, technology in general and the design of interactive 360° web virtual experiences and games in particular. We have the skills to augment and gamify your virtual tours; from story-writing to technical implementations to managing international collaborations in the cultural and education sectors.
<br /><br />
Our developers and game designers are ready to assist from London or Nancy. We work with customers in Europe, the USA and in Northern Africa.
<br /><br />
Technology is redefining what is possible in the Culture, Education and Heritage sectors. From Gamification to VR, there are countless opportunities. Let’s work together.
<br /><br />
                            </SectionText>)}

                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                    >
                        <Box position="relative">
                            <img className={classes.image}
                                alt="Mikael Wozniak"
                                src="/static/mikael_wozniak.jpg"
                            />
                            <SectionText>
                                <div className={classes.mikael}>
                                {getI18nText(lang, "aboutus", "director")}
                                </div>
                            </SectionText>
                        </Box>
                    </Grid>
                </Grid>

                <div className={classes.linkphrase}>
                <SectionText> 
                {getI18nText(lang, "aboutus", "optimosoft")}
                        &nbsp;<Link
          variant="body1"
          align="center"                        
                href="https://optimosoft.com"
                >
                Optimosoft
                </Link>
                </SectionText>
 
                </div>
            </Container>


        </Section>
    );
}

AboutUs.propTypes = {

};

export default AboutUs;
