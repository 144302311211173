//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Container,
    List,
    ListItem,
    ListItemText,
    Typography,
    makeStyles,
    ListItemIcon,
    Divider,
    //   Accordion,
    //   AccordionSummary,
    //   AccordionDetails
} from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { brandingOrange } from 'src/theme';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.dark,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        width: '100%',
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
}));

class UseCase {
    constructor(heading, explanation) {
        this.heading = heading;
        this.explanation = explanation;
    }
}

function UseCases({ className, ...rest }) {
    const classes = useStyles();
    const lang = getLanguageCode(useLocation());


    var usecases = [
        new UseCase(getI18nText(lang,"usecases","case_mixincrease"),getI18nText(lang,"usecases","case_mixincrease_description")),
        new UseCase(getI18nText(lang,"usecases","case_helpschools"),getI18nText(lang,"usecases","case_helpschools_description")),
        new UseCase(getI18nText(lang,"usecases","case_languages"),getI18nText(lang,"usecases","case_languages_description")),
        new UseCase(getI18nText(lang,"usecases","case_expand"),getI18nText(lang,"usecases","case_expand_description")),
        new UseCase(getI18nText(lang,"usecases","case_newaudiences"),getI18nText(lang,"usecases","case_newaudiences_description")),
        new UseCase(getI18nText(lang,"usecases","case_stayopen"),getI18nText(lang,"usecases","case_stayopen_description")),
        new UseCase(getI18nText(lang,"usecases","case_escapegames"),getI18nText(lang,"usecases","case_escapegames_description")),
        new UseCase(getI18nText(lang,"usecases","case_monetize"),getI18nText(lang,"usecases","case_monetize_description"))
    ];

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="h1"
                    align="center"
                    color="textPrimary"
                >
                    {getI18nText(lang,"usecases","title")}
        </Typography>
                <Box my={3}>
                    <Divider />
                </Box>
                <Box mt={3}>
                    {
                        usecases.map((u,i) => (
                            <div key={i}>
                                <Accordion defaultExpanded={i==0?true:false}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{fill:brandingOrange}} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h4" color="textPrimary" className={classes.heading}>{u.heading}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            dangerouslySetInnerHTML={{ __html: u.explanation }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        ))
                    }
                </Box>
            </Container>
        </div>
    );
}

UseCases.propTypes = {
    className: PropTypes.string
};

export default UseCases;
