// @ts-check

import { Box, Typography, CircularProgress, Tooltip, Container, makeStyles, Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';

//typically all of this would go into a container 

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    }
}));


function SectionImpl({ children, dark, title }) {
    const classes = useStyles();
    /** @type {any} */
    const theme = useTheme();
    return (
        <div
            className={classes.root}
            style={{ backgroundColor: dark ? theme.palette.background.dark  : theme.palette.background.default }}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="h1"
                    align="center"
                    color="textPrimary"
                >
                    {title}
                </Typography>
                <Box my={3}>
                    <Divider />
                </Box>
                <Box mt={3}>
                    {children}
                </Box>


            </Container>
        </div>
    );
}

SectionImpl.propTypes = {
    dark: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
};

export const Section = SectionImpl;



/*
export const Section = (props) => {
    //p mt etc: https://getbootstrap.com/docs/4.4/utilities/spacing/
    return (
        <Paper>
            <Box p={3} mt={3}>
                {props.children}
            </Box>
        </Paper>
    )
}
*/
export const TabbedSubSection = (props) => (<Box m={"0px 10px"} p={2}>{props.children}</Box>);

//https://material-ui.com/components/typography/

export const SectionMainTitle = ({ text }) => (<Typography variant="h2" color="textPrimary" style={{ margin: "20px 0px" }}>{text}</Typography>)
export const SectionTitle = ({ text }) => (<Typography variant="h3" color="textPrimary" style={{ margin: "15px 0px" }}>{text}</Typography>)
export const SectionSubTitle = ({ text }) => (<Typography variant="h4" color="textPrimary" style={{ margin: "10px 0px" }}>{text}</Typography>)
export const SectionText = (props) => (<Typography variant="body1" color="textPrimary">{props.children}</Typography>)
export const SectionDataText = (props) => (<Typography variant="body2" color="textPrimary">{props.children}</Typography>)
export const ImportantText = (props) => (<span style={{ fontWeight: "bold" }}>{props.children}</span>)

export const LoadingSection = () => {
    return (<CircularProgress style={{ display: "block", "marginLeft": "auto", "marginRight": "auto", "marginTop": "20px", "marginBottom": "20px" }} />);
};

export const SectionIconButton = ({ label, handler, icon, color = "primary" }) => {
    return (
        <Tooltip title={label} key={label}>
            <IconButton
                // @ts-ignore
                color={color} aria-label={label} onClick={handler} >
                {icon}
            </IconButton>
        </Tooltip>
    )

}