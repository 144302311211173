// @ts-check

import React from 'react';
import Hero from 'src/components/EpicVisit360/Hero';
import Video from 'src/components/EpicVisit360/Video';
import FeatureList from 'src/components/EpicVisit360/FeatureList';
import IllustratedIdeas from 'src/components/EpicVisit360/IllustratedIdeas';
import UseCases from 'src/components/EpicVisit360/UseCases';
import FAQS from 'src/components/EpicVisit360/FAQS';
import Screenshots from 'src/components/EpicVisit360/Screenshots';
import ContactUs from 'src/components/EpicVisit360/ContactUs';
import PageWrapper from './PageWrapper';
import { useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText } from 'src/utils/i18n';
import ProductIntro from 'src/components/EpicVisit360/ProductIntro';
import PricingView from './PricingView';
import PricingTable from 'src/components/EpicVisit360/PricingTable';
import QuoteForm from 'src/components/EpicVisit360/QuoteForm';


function PricingPage() {
  const lang = getLanguageCode(useLocation());
  return (
    <PageWrapper title={getI18nText(lang,"menu","pricing")} >
      <ProductIntro pricing={true} />
      <PricingTable />
      <QuoteForm/>
    </PageWrapper>
  );
}
export default PricingPage;
