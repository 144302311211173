//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';



const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  dot:{
      fill:theme.palette.primary.main
  },
  feature:{
    color:theme.palette.text.primary,
      "& > strong":{
        color:theme.palette.secondary.main
      }
  }
}));


function FeatureList({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  var features = [
    getI18nText(lang,"features","feature_questions"),
    getI18nText(lang,"features","feature_multilingual"),
    getI18nText(lang,"features","feature_inventory"),
    getI18nText(lang,"features","feature_multidevices"),
    getI18nText(lang,"features","feature_minigames"),
    getI18nText(lang,"features","feature_menu"),
    getI18nText(lang,"features","feature_medias"),
    getI18nText(lang,"features","feature_artefacts"),
    getI18nText(lang,"features","feature_custom"),
    getI18nText(lang,"features","feature_etc")
  ];

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          {getI18nText(lang,"features","title")}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>        
        <Box mt={3}>
        <List dense={false}>
        {
                    features.map((f,i)=>(
                        <ListItem key={i}>
                          <ListItemIcon>
                              <ArrowRightIcon className={classes.dot} />
                          </ListItemIcon>                        
                              <ListItemText
                                  primaryTypographyProps={{variant:"h4"}}
                                  primary={( <div className={classes.feature} dangerouslySetInnerHTML={{__html: f}}></div> )}
                              />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
      </Container>
    </div>
  );
}

FeatureList.propTypes = {
  className: PropTypes.string
};

export default FeatureList;
