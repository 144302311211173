// @ts-check

import React from 'react';
import { useLocation } from 'react-router-dom';
import AboutUs from 'src/components/EpicVisit360/AboutUs';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import PageWrapper from './PageWrapper';


function AboutUsPage() {
  const lang = getLanguageCode(useLocation());
  return (
    <PageWrapper title={getI18nText(lang, "menu", "aboutus")} >
      <AboutUs/>
    </PageWrapper>
  );
}
export default AboutUsPage;
