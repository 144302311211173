//@ts-check
import {
    makeStyles
} from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { Section, SectionText } from './Section';
import PropTypes from 'prop-types';



const useStyles = makeStyles((theme) => ({
  root: {
      
  }
}));

function ProductIntro(props) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());
  //console.error("prcing? "+JSON.stringify(props)+" - "+props.pricing+" - "+props.pricing?"pricing":"product")
  if(lang=="en")
    return (
      <Section dark={true} title={getI18nText(lang,"menu",props.pricing?"pricing":"product")}>
        <SectionText>
The gamified web virtual tours we build can be split in two broad categories:

<div style={{margin:30,fontWeight:"bold"}}><ul>
<li>1. Gamified Educational visits with an optional multilingual guide</li>
<li>2. Custom-made games with a story (treasure hunts, escape games, etc.) </li>
</ul></div>

Our first offer leverages and adapts our key features to your space at a  competitive price while still delivering a unique gamified experience with a multilingual guide, many different types of questions, virtual doors to unlock and branding opportunities.
<br/><br/>
The second approach is more custom and typically comes with a unique storyline for the game and custom puzzles and enigmas built around the layout and history of your space. It takes a little more resources to build but leads to a fully unique experience for your users. 
<br/><br/>
        </SectionText>
      </Section>
  );
    else
    return (
        <Section dark={true} title={getI18nText(lang,"menu",props.pricing?"pricing":"product")}>
          <SectionText>
Les visites virtuelles Web gamifiées que nous concevons peuvent être divisées en deux grandes catégories :
<div style={{margin:30,fontWeight:"bold"}}><ul>
   <li>1. Les Visites Éducatives gamifiées avec un guide multilingue en option</li>
   <li>2. Les Jeux sur mesure avec un scénario (chasse au trésor, escape game, etc.) </li>
</ul></div>
Notre première offre exploite et adapte nos fonctionnalités à votre espace à un prix compétitif en offrant une expérience gamifiée unique avec un guide multilingue, de nombreux types de questions, des portes virtuelles à déverrouiller ainsi que des opportunités en termes de marque et/ou identité visuelle.
   <br/><br/>
La seconde approche est plus personnalisée et comprend un scénario unique pour le jeu ainsi que des énigmes et casses-têtes réalisés sur-mesure autour de l'Histoire et l'Agencement de votre Espace. Cela conduit à une expérience totalement unique pour vos Utilisateurs.    
          </SectionText>
        </Section>
    );    
}

ProductIntro.propTypes = {
    pricing: PropTypes.bool    
};

export default ProductIntro;
