// @ts-check

import React from 'react';
import Hero from 'src/components/EpicVisit360/Hero';
import Video from 'src/components/EpicVisit360/Video';
import FeatureList from 'src/components/EpicVisit360/FeatureList';
import IllustratedIdeas from 'src/components/EpicVisit360/IllustratedIdeas';
import UseCases from 'src/components/EpicVisit360/UseCases';
import FAQS from 'src/components/EpicVisit360/FAQS';
import Screenshots from 'src/components/EpicVisit360/Screenshots';
import ContactUs from 'src/components/EpicVisit360/ContactUs';
import PageWrapper from './PageWrapper';
import { useLocation } from 'react-router-dom';
import { getLanguageCode,getI18nText, getI18nSection } from 'src/utils/i18n';
import CaseStudy from 'src/components/EpicVisit360/CaseStudy';
import { CaseStudyData } from 'src/components/EpicVisit360/CaseStudyData';
import ProductIntro from 'src/components/EpicVisit360/ProductIntro';


function ProductsServicesPage() {
  const lang = getLanguageCode(useLocation());
  /** @type {CaseStudyData} */
  const caseStudy = getI18nSection(lang, "case_study_ksarsaid");
  return (
    <PageWrapper title={getI18nText(lang,"menu","product")} >
      <ProductIntro pricing={false} />
      <FeatureList className={null}/>
      <Video className={null}/>
      <IllustratedIdeas services={true} className={null}/>
      <CaseStudy caseStudyData={caseStudy} />
      <FAQS className={null}/>
      <UseCases className={null}/>    
      <Screenshots className={null}/>      
    </PageWrapper>
  );
}
export default ProductsServicesPage;
