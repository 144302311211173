
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ContactForm from './ContactForm';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  dot:{
      fill:theme.palette.primary.main
  },
  feature:{
    color:theme.palette.text.primary,
      "& > strong":{
        color:theme.palette.secondary.main
      }
  }
}));

function ContactUs({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  var title = getI18nText(lang,"contacts","title")

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <a name="anchor"></a>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>        
        <Box mt={3}>
          <ContactForm/>
        </Box>
      </Container>
    </div>
  );
}

ContactUs.propTypes = {
  className: PropTypes.string
};

export default ContactUs;
