// @ts-check

import AboutUsPage from "src/views/pages/AboutUsPage";
import CaseStudiesPage from "src/views/pages/CaseStudiesPage";
import ContactUsPage from "src/views/pages/ContactUsPage";
import HomePage from "src/views/pages/HomePage";
import PricingPage from "src/views/pages/PricingPage";
import ProductsServicesPage from "src/views/pages/ProductsServicesPage";



export class MenuItem{
    constructor(link,component){
        this.link = link;
        this.component = component; 
    }   
}

const items = [
    new MenuItem("home",HomePage),
    new MenuItem("product",ProductsServicesPage),
    new MenuItem("casestudies",CaseStudiesPage),
    new MenuItem("pricing",PricingPage),
    new MenuItem("aboutus",AboutUsPage),
    new MenuItem("contact",ContactUsPage)
]

export const menuItems = items;