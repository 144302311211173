import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  Divider,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import { getI18nText, getLanguageCode } from 'src/utils/i18n';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

function Video({ className, ...rest }) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());

  var title = getI18nText(lang,"video","title")
  var youtubeUrl = "https://www.youtube.com/embed/"+getI18nText(lang,"video","youtube");

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>        
        <Box mt={3}>
          <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0
        }}
      >
            <iframe src={youtubeUrl}
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    style={{
          frameBorder:0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
            />
            </div>
            </Box>
      </Container>
    </div>
  );
}

Video.propTypes = {
  className: PropTypes.string
};

export default Video;
