//@ts-check
import {
    Box,
    Button,
    Container,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getI18nText, getLanguageCode } from 'src/utils/i18n';
import { CaseStudyData } from './CaseStudyData';
import { Section, SectionText } from './Section';

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid gray",
    backgroundColor: "white",
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    filter: 'drop-shadow(5px 5px 5px #222);'
},  
}));

function CaseStudy(props) {
  const classes = useStyles();
  const lang = getLanguageCode(useLocation());
  /** @type {CaseStudyData} */
  const caseStudyData = props.caseStudyData;
  
  return (
      
    <Section dark={true} title={caseStudyData.name}>
        <Container maxWidth="lg">
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
            >
               <Grid
                    item
                    xs={12}
                    md={7}
                >
                    <Box position="relative">
                    {caseStudyData.media.includes("youtu")?(
                        <div style={{position:"relative",paddingTop:"56.25%"}}>
                            <iframe src={caseStudyData.media}
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        frameBorder="0"
                        title='video'
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                            }}
                            />
                        </div>
  
                    )
                    :(<img className={classes.image} src={caseStudyData.media}/>)}
                    </Box>
                </Grid>            
                <Grid
                    item
                    xs={12}
                    md={5}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                    >
                        <img width="40px" height="30px" src={"/static/flags/"+caseStudyData.country+".png"}/><br/>
                        <SectionText><b>{getI18nText(lang,"casestudies","location")}</b>:&nbsp;{caseStudyData.location}</SectionText><br/>
                        {caseStudyData.link ? (<Button target="_blank" href={caseStudyData.link} startIcon={<VideogameAssetIcon />}
                            variant="contained" style={{textTransform: "none", backgroundColor:"#dd5c35",color:"white",width:"50%"}}>
                            <Typography variant="h5">{getI18nText(lang,"casestudies","play")}</Typography>
                        </Button>):(<Typography variant="h4"><b>{getI18nText(lang,"casestudies","soon")}</b></Typography>)}
                    </Box>
                </Grid>
 
            </Grid>
            <br/><br/>
            <SectionText><b>{caseStudyData.story }</b></SectionText>
            <SectionText>{caseStudyData.description}</SectionText>
            </div>
        </Container>


    </Section>
    
);
}

CaseStudy.propTypes = {
    caseStudyData: PropTypes.any    
};

export default CaseStudy;
