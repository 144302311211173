// @ts-check

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CaseStudy from 'src/components/EpicVisit360/CaseStudy';
import CaseStudyIntro from 'src/components/EpicVisit360/CaseStudyIntro';
import UseCases from 'src/components/EpicVisit360/UseCases';
import { getI18nSection, getI18nText, getLanguageCode } from 'src/utils/i18n';
import PageWrapper from './PageWrapper';
import axios from 'src/utils/axios';
import { CaseStudyData } from 'src/components/EpicVisit360/CaseStudyData';
import { Box, CircularProgress } from '@material-ui/core';

function CaseStudiesPage() {
  const lang = getLanguageCode(useLocation());

  const [casestudies, setCasestudies] = useState([]);

  useEffect(() => {
    async function fetchMyAPI() {
      const url = "https://v7l13ydlv2.execute-api.eu-west-2.amazonaws.com/dev/gamified-tours" ; 
      const response = await axios.get(url);
      const allTours = response.data; 
      const relevantTours = allTours.filter((tour)=>tour.casestudy).sort((a, b) => (a.order > b.order) ? 1 : -1)
      const caseStudyDatas = relevantTours.map(tour=>{
        //const link = tour.link+"?language="+lang+"&lang="+lang+"splashLogo=./bundle/epictour/images/logo/epicvisit360_b.png";
        const link = "https://v7l13ydlv2.execute-api.eu-west-2.amazonaws.com/dev/gamified-tours/"+tour.id+"/redirect?language="+lang+"&lang="+lang+"&splashLogo=./bundle/epictour/images/logo/epicvisit360_b.png";
        const name = tour["name_"+lang] ;
        let prefix = "";
        if(tour.type === "CG"){
          prefix = lang == "fr"?"Exemple de Jeu customisé":"Custom Game Example";
        } else {
          prefix = lang == "fr"?"Exemple de Visite virtuelle guidée":"Guided Virtual Tour Example";
        }
        return new CaseStudyData(prefix+": '"+name+"'",tour["location_"+lang],tour["country"],tour["story_"+lang],tour["description_"+lang],tour["media_"+lang],link);
      });
      setCasestudies(caseStudyDatas);
    }
    fetchMyAPI()
    return ()=>{};
  },[]);//[] only on mount, not update!, be very careful to avoid pricey lambda calls infinite loops!!!!

  // <CaseStudy caseStudyData={getI18nSection(lang, "case_study_ksarsaid")} />
  // <CaseStudy caseStudyData={getI18nSection(lang, "case_study_soissons")} />
  return (
    <PageWrapper title={getI18nText(lang,"menu","casestudies")} >
      <CaseStudyIntro />
      {
        casestudies.length==0 && (<div>
          <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress />
              </Box>
        </div>)
      }
      {
        casestudies.map(casestudy=>(
          <CaseStudy caseStudyData={casestudy} />
        ))
      }

    </PageWrapper>
  );
}
export default CaseStudiesPage;
